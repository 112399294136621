/*
  A simple login form.
*/
/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import "./login-form.css"
import { NotificationManager } from "react-notifications"

import { Row, Button, Col, Inputs, Box } from "adminlte-2-react"
import { handleLogin, setUser } from "../../services/auth"
import { resetPassword } from "../../services/users"

import Helmet from "react-helmet"
import InfoModal from "../info-modal/info-modal"
const { Text, Checkbox } = Inputs

import { BrowserRouter as Router } from "react-router-dom"
const axios = require('axios').default
const INFO_MODAL = process.env.INFO_MODAL
const SERVER = process.env.GATSBY_API_URL

let _this

class LoginForm extends React.Component {
  constructor(props) {
    super(props)

    _this = this

    this.state = {
      message: "",
      email: "",
      password: "",
      loaded: true,
      showInfo: false,
      INFO_MODAL: INFO_MODAL === "true",
      emailCheck: false,
    }
  }

  render() {
    const siteTitle = "LaunchpadIP.net"

    return (
      <>
        <Helmet title={siteTitle} />
        <Row className="Login-Container">
          <Col xs={8} md={4}>
            <Box
              loaded={_this.state.loaded}
              className="Login-Box"
              footer={
                <Row className="Login-Box-Row">
                  <Col className="button-container1" xs={12} md={12}>
                    <Button
                      type="primary"
                      text="Create"
                      className="login-buttom-type1"
                      onClick={this.createClick}
                    />
                    <Button
                      type="primary"
                      text="Login"
                      className="login-buttom-type1"
                      onClick={this.loginClick}
                    />
                  </Col>
                  <Col className="button-container2" xs={12} md={12}>
                    <Button
                      type="primary"
                      text="Reset Password"
                      className="login-buttom-type2"
                      onClick={this.resetPasswordClick}
                    />
                  </Col>
                </Row>
              }
            >
              <Text
                id="email"
                name="email"
                placeholder="Enter Email"
                label="Email"
                labelPosition="above"
                onChange={this.handleUpdate}
                onKeyDown={this.handleKeyDown}
              />
              <Text
                id="password"
                name="password"
                inputType="password"
                placeholder="Enter password"
                label="Password"
                labelPosition="above"
                onChange={this.handleUpdate}
                onKeyDown={this.handleKeyDown}
              />
              <div className="login-email-check-container">
                <b></b>
                <Checkbox
                  value={_this.state.emailCheck} // mark as checked
                  text={
                    _this.state.emailCheck
                      ? "Email Check Enabled"
                      : "Email Check Disabled"
                  }
                  labelPosition="rigth"
                  labelXs={0}
                  name="emailCheck"
                  onChange={this.handleUpdate}
                />
              </div>
            </Box>
          </Col>
          <Col xd={12}></Col>
        </Row>
        <Router basepath="/login">
          <InfoModal
            show={_this.state.showInfo}
            onHide={_this.onInfoHide}
            path="/"
          />
        </Router>
      </>
    )
  }

  handleKeyDown(e) {
    if (e.key === "Enter") {
      _this.loginClick(e)
    }
  }
  handleUpdate(event) {
    const name = event.target.name

    let value = event.target.value

    // Turn on/off email address validator
    if (name === "emailCheck") {
      value = !_this.state.emailCheck
    }
    _this.setState({
      [name]: value,
    })
  }
  async createClick(event) {
    event.preventDefault()
    if (!_this.validateEntries()) {
      setTimeout(() => {
        NotificationManager.error(_this.state.message, "Error", 2000)
      }, 300)
      return
    }
    _this.handleLoaded(false)

    try {
      const options = {
        method: 'post',
        url: `${SERVER}/users/`,
        data: {
          user: {
            email: _this.state.email,
            password: _this.state.password,
            ignoreEmailCheck: !_this.state.emailCheck,
          }
        }
      }
      const data = await axios(options)
      const users = data.data //await data.json()

      setUser({
        _id: users.user._id,
        email: users.user.email,
        jwt: users.token,
      })
      _this.handleLoaded(true)
      NotificationManager.success("Create Success", "Success", 900)

      _this.goToDashboard()
    } catch (err) {
      const msg = err.response.data || err.message
      _this.handleLoaded(true)
      _this.setState(prevState => ({
        message: msg,
      }))
      setTimeout(() => {
        NotificationManager.error(_this.state.message, "Error", 2000)
      }, 300)
    }
  }
  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }
  async loginClick(event) {
    event.preventDefault()
    // Validate Entries
    if (!_this.validateEntries()) {
      setTimeout(() => {
        NotificationManager.error(_this.state.message, "Error", 2000)
      }, 300)
      return
    }
    // Login request
    _this.handleLoaded(false)
    const result = await handleLogin(_this.state)
    _this.handleLoaded(true)
    if (!result) {
      NotificationManager.error("Login Error", "Login Error", 2000)
      return
    }
    NotificationManager.success("Login Success", "Success", 900)
    _this.goToDashboard()
  }
  validateEntries() {
    const isEmail = _this.state.emailCheck
      ? _this.validateEmail(_this.state.email)
      : true
    console.log(`isEmail : ${isEmail}`)

    if (!_this.state.email && !_this.state.password) {
      _this.setState({
        message: "Email and password required.",
      })
      return false
    } else if (!_this.state.email) {
      _this.setState({
        message: "Email  required.",
      })
      return false
    } else if (!_this.state.password) {
      _this.setState({
        message: "Password  required.",
      })
      return false
    } else if (!isEmail) {
      _this.setState(prevState => ({
        message: "Must be Email Format",
      }))
      return false
    }
    return true
  }
  handleLoaded(val) {
    _this.setState({
      loaded: val,
    })
  }
  async resetPasswordClick() {
    try {
      const email = _this.state.email
      const isEmail = _this.state.emailCheck ? _this.validateEmail(email) : true
      if (!email || typeof email !== "string") {
        throw new Error("Email  required.")
      }
      if (!isEmail) {
        throw new Error("Must be Email Format")
      }
      _this.handleLoaded(false)
      const result = await resetPassword(email)
      console.log(result)
      if (!result.success) {
        throw new Error("Email not found")
      }

      NotificationManager.success("New password sent to your email", "Success")
      _this.handleLoaded(true)
    } catch (error) {
      _this.handleLoaded(true)

      NotificationManager.error(error.message, "Error")
    }
  }
  goToDashboard() {
    let url = `/`
    const payloadId = _this.props.queryPayloadId
    if (payloadId) {
      // redirect query parameter to a url
      url = `/?shareId=${payloadId}`
    }
    if (_this.state.INFO_MODAL) {
      _this.setState({
        showInfo: true,
      })
    } else {
      setTimeout(() => {
        navigate(url)
      }, 1500)
    }
  }
  onInfoHide() {
    let url = `/`
    const payloadId = _this.props.queryPayloadId

    if (payloadId) {
      // redirect query parameter to a url
      url = `/?shareId=${payloadId}`
    }
    setTimeout(() => {
      navigate(url)
    }, 500)

    _this.setState({
      showInfo: false,
    })
  }
}
LoginForm.propTypes = {
  queryPayloadId: PropTypes.string,
}
export default LoginForm
