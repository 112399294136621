/*
  A simple login form.
*/
/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"

import { Content, Callout, Box, Button } from "adminlte-2-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./info-modal.css"

// const TEXT = `This app is available for beta testers.
//  The app is not yet fully functional.`

const TEXT = `
Welcome to LaunchpadIP.net, a free application for establishing timestamped proof of existence (POE) for sensitive data such as creative works, designs, trade secrets, invention disclosures, and confidentiality agreements; and for sharing sensitive data with immutable, timestamped access records.  LaunchpadIP.net records transactions including saving, sharing, downloading, and viewing of sensitive data in a public blockchain (BCH) which provides immutable proof of each recorded transaction.  LaunchpadIP.net is patent pending.


Caution:  The LaunchpadIP.net web application is currently at a pre-release state.  Not all controls are functional.  Specifically, user-to-user sharing of payload data is not currently functional.  It is possible that uploaded payload data and/or metadata may be lost.  To help avoid loss of data and POE, users of this pre-released application are urged to Download and save local copies of important uploaded payload data and to save Transaction ID (Tx) and IPFS Hash associated with downloaded payload data.  User accepts all risks associated with use of LaunchpadIP.net.

User acknowledges that all confidential information received via LaunchpadIP.net constitutes secret information that remains the sole property of its creator.  Unless express agreement dictates otherwise, User acknowledges that all confidential information received via LaunchpadIP.net constitutes trade secret information for as long as such information remains a trade secret under applicable law.  Such trade secret protection shall survive the termination of this agreement.  User shall keep such information confidential and shall only use such trade secret information according to the purpose and scope of this agreement or separate express agreement.

Please direct questions, comments, or requests for support to info@launchpadip.com.

I accept these terms
`

let _this
class InfoModal extends React.Component {
  constructor(props) {
    super(props)

    _this = this

    this.state = {}
  }
  render() {
    return (
      <div className="info-modal-container">
        <Content
          title={
            <div className="info-modal-tittle">
              {/*               <FontAwesomeIcon
                className="warning-icon"
                icon="exclamation-triangle"
                pull="left"
              /> */}
              <p>WELCOME</p>
            </div>
          }
          modal
          show={_this.props.show}
          modalCloseButton={false}
          modalSize="lg"
          modalFooter={
            <React.Fragment>
              <div
                className="info-modal-footer"
              >
                <Button
                  type="primary"
                  text="I accept these terms."
                  onClick={_this.props.onHide}
                />
              </div>
            </React.Fragment>
          }
        >
          <Callout className="info-modal-txt" >
            <span>
              Welcome to LaunchpadIP.net, an application for establishing timestamped proof of existence (POE) for sensitive data such as creative works, designs, trade secrets, invention disclosures, and confidentiality agreements; and for sharing sensitive data with immutable, timestamped access records. LaunchpadIP.net records transactions including saving, sharing, downloading, and viewing of sensitive data in a public blockchain (BCH) which provides immutable proof of each recorded transaction. LaunchpadIP.net is patent pending.
           </span>
            <br></br>
            <br></br>
            <span className="info-modal-txt-2nd-paragraph">
              User acknowledges that all confidential information received via LaunchpadIP.net constitutes secret information that remains the sole property of its creator. Unless express agreement dictates otherwise, User acknowledges that all confidential information received via LaunchpadIP.net constitutes trade secret information for as long as such information remains a trade secret under applicable law. Such trade secret protection shall survive the termination of this agreement. User shall keep such information confidential and shall only use such trade secret information according to the purpose and scope of this agreement or separate express agreement.
           </span>
            <br></br>
            <br></br>

            <span className="info-modal-txt-2nd-paragraph">
              Please direct questions, comments, or requests for support
            to <b><a href="email:info@launchpadip.com">info@launchpadip.com</a>.</b>
            </span>
          </Callout>
        </Content>
      </div>
    )
  }
}

// {
//  _this.props.text ? this.props.text : TEXT
//  }

InfoModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  text: PropTypes.string,
}

export default InfoModal
