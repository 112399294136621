import React from "react"
//import Helmet from 'react-helmet'
import { navigate } from "gatsby"
import { isLoggedIn } from "../services/auth"
import Layout from "../components/layout"
import { useQueryParam, StringParam } from "use-query-params"

let LoginForm =
  typeof window !== `undefined`
    ? require("../components/login-form").default
    : null
const Login = () => {
  const [payloadId] = useQueryParam("shareId", StringParam)
  let route = "/"
  // verify if the url has the search parameters
  if (payloadId) {
    route = `/?shareId=${payloadId}`
  }

  if (isLoggedIn() && typeof window !== `undefined`) {
    navigate(route)
  }
  return (
    <Layout hideFooter={true}>
      {typeof window !== `undefined` && (
        <LoginForm queryPayloadId={payloadId} />
      )}
    </Layout>
  )
}

export default Login
